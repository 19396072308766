


































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';
import Checkbox from '@/components/Input/Checkbox/index.vue';

@Component({
  components: {
    Loading,
    Checkbox
  }
})
export default class MaterialDownload extends Vue {
  @Prop() padding!: string;
  @Prop() title!: string;
  @Prop() isLoading!: boolean;
  @Prop() activeButton!: boolean;
  @Prop() resetChecked!: boolean;

  private checkedAll = false;

  setCheckAll() {
    this.checkedAll = !this.checkedAll;

    this.$emit('setCheckAll', this.checkedAll);
  }

  handleDownload() {
    this.$emit('download');
  }

  @Watch('resetChecked')
  resetCheckedAll() {
    this.checkedAll = this.resetChecked;
  }
}
