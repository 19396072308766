





































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';

import RegroupType from '@/components/RegroupType/index.vue';
import SubjectList from '@/components/Subject/SubjectList/index.vue';
import IconStatus from '@/components/IconStatus/index.vue';
import SubjectQuestions from '@/components/Subject/SubjectQuestions/SubjectQuestions.vue';

import listAccordion from '@/mixins/listAccordion';

import { hasPermissionExercises } from '@/share/Util/Permissions/exercises';

import { ExercisesMaterialsCount } from '@/globalInterfaces/Book';

interface InterfaceAllChecked {
  videos: [];
  materials: [];
  exercices: [];
}

interface MaterialCategoryStore {
  categories: Array<Category>;
  context: string;
}

interface Category {
  idCategory: number;
  checked: boolean;
}

const TYPE = 'exercices';
const DEFAULT_INFO = '00 questões | 00:00:00';
const THREE_MINUTES = 60 * 3;
const ROUTE_BOOK = 'BooksView';

@Component({
  mixins: [listAccordion],
  components: {
    RegroupType,
    SubjectList,
    IconStatus,
    SubjectQuestions
  }
})
export default class ExerciseTab extends Vue {
  @Prop({ default: true }) isPermission!: boolean;
  @Prop({ default: true }) isClickable!: boolean;
  @Prop({ default: false }) showLesson!: boolean;
  @Prop() allChecked!: InterfaceAllChecked;
  @Prop() topic!: Record<string, any>;
  @Prop() allQuestions!: Array<Record<string, any>>;
  @Prop() categoryInformation!: { [key: number]: ExercisesMaterialsCount };
  @Prop() setData!: Function;

  get isBook() {
    return this.$route.name === ROUTE_BOOK;
  }

  setTotalHours(files: Array<Record<string, any>>) {
    const totalQuestions = files.reduce(
      (acc, exercise: Record<string, any>) => acc + this.totalQuestions(exercise.idExercice),
      0
    );

    if (!totalQuestions) return '';

    const time = this.setTime(totalQuestions * THREE_MINUTES);

    if (totalQuestions > 1) {
      return `${totalQuestions} questões | ${time}`;
    }

    return `${totalQuestions} questão | ${time}`;
  }

  setInfoQuestions(category: number) {
    const contentCategory = this.categoryInformation[category];

    if (contentCategory) {
      if (!contentCategory.totalQuestions) return this.setTime(contentCategory.totalTime);

      return `${contentCategory.totalQuestions} ${
        contentCategory.totalQuestions > 1 ? 'questões' : 'questão'
      } ${this.setTime(contentCategory.totalTime, '|')}`;
    }

    return '';
  }

  totalQuestions(exerciseID: number) {
    return (
      this.allQuestions.find(
        (itemExercise: Record<string, any>) => itemExercise.idExercice === exerciseID
      )?.questionCount ?? 0
    );
  }

  isChecked(id: number) {
    const result = this.allChecked.exercices.some((checkedItem: Record<string, any>) => {
      if (checkedItem?.idobject === id) {
        return checkedItem.checked;
      }
      return false;
    });

    return result;
  }

  setTime(timeInSeconds: number, adittional?: string) {
    if (!timeInSeconds) return '';

    return `${adittional || ''} ${moment.utc(timeInSeconds * 1000).format('HH:mm:ss')}`;
  }

  permissionExercise(exercise: Record<string, any>) {
    return hasPermissionExercises(exercise);
  }

  emitClickOpenContent(data: Record<string, any>) {
    this.$emit('click-open-content', data);
  }

  showCategoryContext(files: Array<Record<string, any>>) {
    if (!this.isBook) return files.length;

    const listCategoryContextStore = this.$store.getters.categoryFilterByContext.find(
      (category: MaterialCategoryStore) => category.context === TYPE
    );

    const checkedAll = listCategoryContextStore.categories.every(
      (context: Record<string, any>) => context.checked
    );

    if (checkedAll) return true;

    const currentCategoryId = files[0].IDMaterialCategory;

    const categoryContext = listCategoryContextStore.categories.find(
      (context: Record<string, any>) => context.idCategory === currentCategoryId
    );

    return categoryContext.checked;
  }
}
