


























































































































































import {
  Vue, Component, Prop, Watch 
} from 'vue-property-decorator';

import Close from '@/assets/icons/Close.vue';
import DownloadTab from '@/assets/icons/DownloadTab.vue';

import Tooltip from '@/components/Tooltip/index.vue';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

@Component({
  components: {
    Close,
    DownloadTab,
    Tooltip
  }
})
export default class Tab extends Vue {
  @Prop() id!: string;
  @Prop() padding!: string;
  @Prop() isStopped!: boolean;
  @Prop({ default: false }) isPermission!: boolean;
  @Prop({ default: false }) hasPermissionDownload!: boolean;
  @Prop({ required: false }) permissionsTab!: Record<string, any>;
  @Prop() infoAdditionalContent!: Record<string, any>;
  @Prop() subject!: {};

  private calcPadding = this.padding;
  private lateralPadding = this.padding.split(' ')[1];

  private tabIndex = 0;

  private openTemplateDownload = false;
  private showDownload = false;
  private renderExercise = false;

  async created() {
    if (this.$route.meta?.title === 'Desempenho') {
      this.renderExercise = true;
    }
  }

  mounted() {
    this.resize();
  }

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  get showButtonDownload() {
    return (
      this.tabIndex !== 0 && this.tabIndex !== 1 && this.isPermission && this.hasPermissionDownload
    );
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  setShowDownload(value: boolean) {
    this.showDownload = value;
  }

  setTamplateDownload() {
    this.$emit('toggleDownloadTab', true);
    this.showDownload = !this.showDownload;
    this.openTemplateDownload = !this.openTemplateDownload;
  }

  @Watch('tabIndex')
  closeTab() {
    this.openTemplateDownload = false;
    this.showDownload = false;
    this.$emit('toggleDownloadTab', false);
  }

  @Watch('$responsive.contentwidth')
  resize() {
    if (this.$responsive.contentwidth <= 768) {
      this.calcPadding = `0px calc(${this.lateralPadding} - 26px)`;
    } else {
      this.calcPadding = `0px ${this.lateralPadding}`;
    }
  }

  getInfo(context: string) {
    return `${this.formatCount(
      this.infoAdditionalContent[context].totalCount
    )} ${this.formattedTime(this.infoAdditionalContent[context].totalTime)}`;
  }

  formattedTime(time: number) {
    if (!time) return '';

    const date = new Date(time * 1000);
    const hours = date
      .getUTCHours()
      .toString()
      .padStart(2, '0');
    const minutes = date
      .getUTCMinutes()
      .toString()
      .padStart(2, '0');
    return ` | ${hours}h ${minutes}m`;
  }

  formatCount(count: number) {
    return count < 10 ? `0${count}` : count;
  }

  modifierNumber(listTopics: Array<object>) {
    if (!listTopics || !listTopics.length) {
      return '';
    }

    return listTopics.length < 10 ? `0${listTopics.length}` : listTopics.length;
  }
}
