


















import { Component, Vue, Prop } from 'vue-property-decorator';

const DEFAULT_HREF = 'https://pages.explicae.com.br/compare-os-planos';
const DEFAULT_NAME_BUTTON = 'Quero assinar';
const DEFAULT_DESCRIPTION = 'Assine um plano para ter esse e outros conteúdos disponíveis para os seus estudos.';
const DEFAULT_TITLE = 'Se interessou pelo conteúdo?';

@Component
export default class TabsFeedBackPermission extends Vue {
  @Prop({ default: DEFAULT_HREF }) href!: string;
  @Prop({ default: DEFAULT_TITLE }) title!: string;
  @Prop({ default: DEFAULT_DESCRIPTION }) description!: string;
  @Prop({ default: DEFAULT_NAME_BUTTON }) nameButton!: string;
}
