





































import { Component, Prop, Vue } from 'vue-property-decorator';
import { uniqBy } from 'lodash';

import MaterialList from '@/components/TabsAccordion/MaterialList/index.vue';
import MaterialDownload from '../MaterialDownload/index.vue';

import DownloadUtil from '@/widgets/Util/DownloadFiles/DownloadFiles';

import listAccordion from '@/mixins/listAccordion';

import { ExercisesMaterialsCount } from '@/globalInterfaces/Book';

interface InterfaceMaterials {
  slugfix?: string;
  checked?: boolean;
}

@Component({
  mixins: [listAccordion],
  components: {
    MaterialList,
    MaterialDownload
  }
})
export default class MaterialsTab extends Vue {
  @Prop() padding!: string;
  @Prop({ default: false }) showDownloadTab!: boolean;
  @Prop({ default: false }) showLesson!: boolean;
  @Prop({ default: false }) isPermission!: boolean;
  @Prop({ default: false }) availablePermissionBook!: boolean;
  @Prop({ default: null }) idBook!: null | number;
  @Prop() allChecked!: Array<Record<string, any>>;
  @Prop() topic!: Record<string, any>;
  @Prop() categoryInformation!: { [key: number]: ExercisesMaterialsCount };
  @Prop() checked!: Function;
  @Prop() setData!: Function;

  private checkedAll = false;
  private isLoading = false;
  private countClickCheckAll = 0;

  private checkedMaterials: Array<InterfaceMaterials> = [];

  get newPadding() {
    if (this.padding) {
      if (this.padding.length > 2) {
        let padding = this.padding.split(/[\s,]+/);
        padding = padding[1].split('rem', 1);
        return padding;
      }
      return this.padding;
    }
    return '';
  }

  get AllCheckedMaterials() {
    return this.checkedMaterials.length === this.topic.archives.length;
  }

  async download() {
    try {
      this.isLoading = true;

      await DownloadUtil.downloadFiles(0, this.checkedMaterials, this.$store.getters.token);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao realizar o download.',
        status: 'error'
      });
      console.error({ error });
    } finally {
      this.isLoading = false;
    }
  }

  emitUpdateAllChecked(id: any) {
    this.$emit('checkedMaterials', id);
  }

  setCheckAll(checkedAll: boolean) {
    this.checkedAll = checkedAll;
    this.countClickCheckAll += 1;
  }

  setCheckedMaterials(material: InterfaceMaterials) {
    if (material.checked) {
      const materials = [...this.checkedMaterials, material];
      this.checkedMaterials = uniqBy(materials, 'slugfix');
      return;
    }

    this.checkedMaterials = this.checkedMaterials.filter(
      (materialItem: InterfaceMaterials) => materialItem.slugfix !== material.slugfix
    );
  }

  emitClickOpenContent(data: Record<string, any>) {
    this.$emit('click-open-content', data);
  }
}
