






import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';

const ONE_MINUTE = 60;
const THREE_MINUTES = 3 * ONE_MINUTE;
const THIRTY_MINUTE = ONE_MINUTE * 30;

@Component
export default class InfoCountExercises extends Vue {
  @Prop() totalQuestions!: number;
  @Prop({ default: false }) addingExtraTime!: boolean;

  get showInfoQuestions() {
    const time = this.setTime(this.totalQuestions);

    if (!this.totalQuestions) return time;

    return `${this.totalQuestions} ${this.totalQuestions > 1 ? 'questões' : 'questão'} ${time}`;
  }

  setTime(numberQuestion: number) {
    const additionalTimeForMaterial = this.addingExtraTime ? THIRTY_MINUTE : 0;

    if (!numberQuestion && !additionalTimeForMaterial) return '';

    const formatted = moment
      .utc((numberQuestion * THREE_MINUTES + additionalTimeForMaterial) * 1000)
      .format('HH:mm:ss');

    return formatted;
  }
}
