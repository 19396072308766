



















































































// Dependencies
import {
  Component, Prop, Mixins, Watch 
} from 'vue-property-decorator';

// Components
import Tab from '@/components/Subject/Tab/index.vue';
import TabsFeedBackPermission from '@/components/FeedbackPermission/TabsFeedbackPermission/index.vue';
import MaterialsTab from '@/components/TabsAccordion/MaterialTab/index.vue';
import VideosTab from '@/components/TabsAccordion/VideosTab/index.vue';
import ExerciseTab from '@/components/TabsAccordion/ExerciseTab/index.vue';

// Services
import HistoricService from '@/services/Historic/historicService';
import ExerciseService from '@/services/Exercise/ExerciseService';
import BookService from '@/services/Book/BookService';

// Mixins
import listAccordion from '@/mixins/listAccordion';
import accordionTab from '@/mixins/accordionTab';
import generalBooks from '@/mixins/generalBooks';

import { hasPermissionMaterials } from '@/share/Util/Permissions/materials';
import { hasPermissionExercises } from '@/share/Util/Permissions/exercises';

import { LIST_BOOK_ID } from '@/constant/ListBookId';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import { TopicListBook } from '@/globalInterfaces/Book';
import { TypeMaterial } from '@/services/Historic/historicInterface';

const PADDING_MATERIALS = '0px';

@Component({
  mixins: [listAccordion, accordionTab],
  components: {
    Tab,
    TabsFeedBackPermission,
    MaterialsTab,
    VideosTab,
    ExerciseTab
  }
})
export default class TabSubject extends Mixins(generalBooks) {
  @Prop() lesson!: TopicListBook;
  @Prop() bookSlug!: string;
  @Prop() idBook!: number;
  @Prop({ default: '0rem 1rem' }) padding!: string;

  private showTeacher = true;

  private topic: Record<string, any> = {};
  private allQuestions: Array<Record<string, any>> = [];
  private allChecked: Record<string, any> = {
    videos: [],
    materials: [],
    exercices: []
  };
  private ids: Record<string, any> = {
    videos: [],
    archives: [],
    exercices: []
  };

  private HistoricService = new HistoricService();
  private ExerciseService = new ExerciseService();
  private BookService = new BookService();

  async mounted() {
    await this.loadData();
  }

  get paddingTabMaterials() {
    return this.permissionsTab.archives ? this.padding : PADDING_MATERIALS;
  }

  get permissionsTab() {
    return {
      exercises: true,
      archives: true,
      videos: true
    };
  }

  get permissionExercises() {
    return this.topic.exercices.length
      ? this.topic.exercices.some((exercise: Record<string, any>) => hasPermissionExercises(exercise))
      : true;
  }

  get permissionMaterials() {
    if (this.idBook === LIST_BOOK_ID.REDACTION && this.can(LIST_PERMISSION.ALL_MATERIALS_REDACTION)) return true;

    return this.topic.archives.length
      ? this.topic.archives.some((material: Record<string, any>) => hasPermissionMaterials(material))
      : true;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get infoAdditionalContent() {
    return {
      videos: {
        totalTime: this.getTotalContext(this.lesson, 'videos', 'totalTime'),
        totalCount: this.getTotalContext(this.lesson, 'videos', 'totalCount')
      },
      exercises: {
        totalTime: this.getTotalContext(this.lesson, 'exercices', 'totalTime'),
        totalCount: this.getTotalContext(this.lesson, 'exercices', 'totalCount'),
        totalQuestions: this.getTotalContext(this.lesson, 'exercices', 'totalQuestions')
      },
      materials: {
        totalTime: this.getTotalContext(this.lesson, 'archives', 'totalTime'),
        totalCount: this.getTotalContext(this.lesson, 'archives', 'totalCount'),
        totalQuestions: this.getTotalContext(this.lesson, 'archives', 'totalQuestions')
      }
    };
  }

  getTotalContext(subject: Record<string, any>, context: string, type: string) {
    return Object.values(subject[context]).reduce(
      (acc: number, category: any) => acc + category[type],
      0
    );
  }

  @Watch('lesson')
  async loadData() {
    await this.getTopic();

    this.getIds(this.topic, 'videos', 'idVideo');
    this.getIds(this.topic, 'archives', 'idArchive');
    this.getIds(this.topic, 'exercices', 'idExercice');

    await this.getAllQuestion(this.ids.exercices);
    await this.getAllFilesChecked(this.ids.videos, 'video', 'videos');
    await this.getAllFilesChecked(this.ids.archives, 'materiais', 'materials');
    await this.getAllFilesChecked(this.ids.exercices, 'exercice', 'exercices');
  }

  async getTopic() {
    const id = this.lesson?.idTopic;
    const topicStore = this.verifyTopicStore(this.bookSlug, id);

    if (topicStore && Object.entries(topicStore).length) {
      this.topic = topicStore;
      return;
    }

    const topic = await this.BookService.getBookTopic(this.idBook, id);

    if (topic && Object.entries(topic).length) {
      this.setTopicStore(this.bookSlug, topic);
      this.topic = topic;
    }
  }

  verifyTopicStore(slug: string, idTopic: number) {
    const { bookSlug, topics } = this.$store.getters.topicsBookAndPath;
    if (bookSlug === slug) {
      if (topics.length) {
        const found = topics.find((topic: Record<string, any>) => topic.idTopic === idTopic);

        if (found && Object.entries(found).length) {
          return found;
        }

        return {};
      }

      return {};
    }

    this.$store.dispatch('clearBookTopicAndPath');
    return {};
  }

  setTopicStore(slug: string, topic: Record<string, any>) {
    this.$store.commit('setBookPath', slug);
    this.$store.commit('setTopicsBook', topic);
  }

  setShowTeacher(videos: Array<Record<string, any>>) {
    const checkTeacher = videos.some((video) => this.$store.getters.teachers.some((teacher: Record<string, string>) => video.teachers.some((videoTeachers: Record<string, string>) => {
      if (videoTeachers.idTeacher === teacher.id) {
        return teacher.show;
      }
      return false;
    })));

    return checkTeacher;
  }

  updateAllChecked(event: number) {
    const newAllChecked: Record<string, any> = this.allChecked;

    newAllChecked.materials.push({
      idobject: event,
      checked: true
    });

    this.allChecked = newAllChecked;
  }

  getIds(data: Record<string, any>, param: string, idType: string) {
    if (!data?.topics || data?.topics.length <= 0) {
      if (data[param] && data[param].length > 0) {
        const ids = data[param].map((item: Record<string, any>) => item[idType]);
        this.ids[param] = [...this.ids[param], ...ids];
      }
    } else if (data?.topics && data?.topics.length) {
      data.topics.forEach((topic: Record<string, any>) => {
        this.getIds(topic, param, idType);
      });
    }
  }

  async getAllFilesChecked(
    ids: Array<number>,
    historicParam: TypeMaterial,
    allChechedParam: string
  ) {
    if (ids && ids.length > 99) {
      const response = await this.getHistoric(historicParam, ids.slice(0, 100));

      this.allChecked[allChechedParam].push(...response);
      await this.getAllFilesChecked(ids.slice(100), historicParam, allChechedParam);
    } else if (ids && ids.length) {
      const response = await this.getHistoric(historicParam, ids);

      this.allChecked[allChechedParam].push(...response);
    }
  }

  async getAllQuestion(exercises: Array<number>) {
    if (exercises && exercises.length > 99) {
      const response = await this.ExerciseService.getQuestionsByExercise(exercises.slice(0, 100));

      this.allQuestions = [...this.allQuestions, ...response];
      await this.getAllQuestion(exercises.slice(100));
    } else if (exercises && exercises.length) {
      const response = await this.ExerciseService.getQuestionsByExercise(exercises);

      this.allQuestions = [...this.allQuestions, ...response];
    }
  }

  async getHistoric(type: TypeMaterial, listIds: Array<number>) {
    try {
      const response = await this.HistoricService.getHistoric({ type, listIds });

      return response || [];
    } catch (error) {
      return [];
    }
  }

  setData(item: Record<string, any>, type: string) {
    const { path } = this.$route.params;

    if (type === 'exercise') {
      return {
        to: '/exercicios',
        from: `caderno/${path}`,
        file: item,
        id: item.idExercice,
        type: 'instructions'
      };
    }

    if (type === 'video') {
      return {
        to: '/videos',
        from: `caderno/${path}`,
        file: item,
        id: item.idVideo
      };
    }

    return {
      to: '/materiais',
      from: `caderno/${path}`,
      file: item,
      id: item.idArchive
    };
  }
}
